import Layout from '../../Layout';
import { LanguageContext } from '../../Components/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import handleTransformLanger from '../../functions/handleTransformLang'

export default function Products({ data }) {
    return (
        <Layout headData={data.head}>
            <InnerPage data={data} />
        </Layout>
    )
}

function InnerPage({ data }) {

    const { language } = useContext(LanguageContext);
    const [content, setContent] = useState(handleTransformLanger(data));
    useEffect(() => {
        setContent(handleTransformLanger(data))
    }, [language,data])
    return (
        <>
            <div className="py-14 md:py-20 bg-gray-900 pb-20 flex flex-col items-center justify-center dark:bg-gradient-to-bl dark:from-indigo-950 dark:via-[#140b2a] dark:to-gray-950 bg-gradient-to-bl from-gray-200 via-gray-100 to-white">
                <div className='mx-8 md:mx-24 lg:mx-28 xl:mx-36 2xl:mx-44 max-w-5xl py-12'>
                    <h1 className='text-gray-800 dark:text-white text-3xl md:text-3xl lg:text-4xl xl:text-4xl pb-10 font-semibold ml-4'>
                        {content.h1}
                    </h1>
                    <ul className=' grid justify-center items-center gap-6 overflow-hidden shadow-xl dark:shadow-black lg:grid-cols-2 grid-cols-1 px-4 md:px-8 lg:px-12 py-12  bg-blue-100 dark:bg-indigo-950 md:rounded-[30px] rounded-[15px] mb-12'>
                        <li className='h-full'>
                            <p className='pb-6 text-gray-950/80 dark:text-white font-semibold text-2xl md:text-3xl lg:text-4xl'>
                                {content.title}
                            </p>
                            <p className=' text-gray-950/80 dark:text-white'>
                                {content.brief}
                            </p>
                        </li>
                        <li className='overflow-hidden relative '>

                            <div className=''>
                                <img
                                    className=' rounded-md'
                                    src='/images/products/newproduct.webp'
                                >
                                </img>
                            </div>
                        </li>
                        <li className=' lg:col-span-2 '>

                            <p className=' text-gray-950/80 dark:text-white'>
                                {content.description}
                            </p>
                        </li>

                        <li className=' lg:col-span-2 '>
                            <p className='py-4 text-center text-gray-950/80 dark:text-white font-semibold text-2xl md:text-2xl lg:text-3xl'>
                                {content.FeaturesH}
                            </p>
                        </li>
                        <div className='lg:col-span-2 flex flex-wrap justify-start w-full'>
                            {Object.values(content.features).map((feature, index) =>
                                <div key={index} className='flex flex-col w-full sm:w-1/2 lg:w-1/3  px-3 '>
                                    <div className='py-2 flex justify-center items-center h-[128px]'>
                                        <img
                                            className=' object-contain h-full rounded-md'
                                            src={feature.src}
                                        >
                                        </img>
                                    </div>
                                    <p className='py-4  text-gray-950/80 text-center dark:text-white font-semibold text-xl md:text-xl lg:text-2xl'>
                                        {feature.title}
                                    </p>
                                </div>
                            )
                            }
                        </div>

                        {(data?.websitelink) &&
                            <li className=' lg:col-span-2 flex justify-end'>
                                <a href={data?.websitelink} target="_blank" rel="noopener noreferrer" className='flex items-center  text-2xl text-[#0083cb] dark:text-[#00a7df] '>
                                    {content?.linktext}

                                    <svg viewBox="0 0 24 24"
                                        className='h-8 ml-6 fill-transparent stroke-[#0083cb] dark:stroke-[#00a7df] '
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                        <g id="SVGRepo_iconCarrier">
                                            <path
                                                d="M13 15L16 12M16 12L13 9M16 12H8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"

                                                strokeWidth={1}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </>

    );
}